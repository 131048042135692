<template>
  <el-card class='account-remittance-not-account business-handling my-card' :body-style="cardBody">
    <el-page-header @back="$router.go(-1)" :title="$t('Back')">
      <template slot="content"><span style="font-size:14px">{{$t('reversalBusiness')}} / {{$t(mateParam.text)}}</span></template>
    </el-page-header>

    <div class="form-container">
      <el-divider content-position="left">
        <el-form inline ref="queryForm" :model="queryForm" :rules="rules" style="height:45px;overflow:hidden;">
          <el-form-item :label="$t('tradingRecord')" prop="param" style="margin-bottom:0">
            <el-input size="mini" v-model="queryForm.param" :placeholder="$t('inputTradingCode')" style="width:200px" />
          </el-form-item>
          <el-form-item style="margin-bottom:0">
            <el-button size="mini" type="primary" @click="onQuery('queryForm')">{{$t('query')}}{{$t('tradingRecord')}}</el-button>
          </el-form-item>
        </el-form>
      </el-divider>
      <el-form v-if="tradingRecord" ref="tradingRecord" :rules="rules2" :model="tradingRecord" label-position="top">
        <el-input type="hidden" size="medium" v-model="tradingRecord.businessCode"></el-input>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-form-item :label="$t('businessType')">
              <el-input size="medium" v-model="tradingRecord.businessName" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item :label="$t('transactionAmount')">
              <el-input size="medium" v-model="tradingRecord.amount" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item :label="$t('tradingTime')">
              <el-input size="medium" v-model="tradingRecord.createTime" readonly></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6">
            <el-form-item :label="$t('idNumber')">
              <el-input size="medium" v-model="tradingRecord.idCard" readonly></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item :label="$t('transactionRemark')">
              <el-input size="medium" v-model="tradingRecord.transactionRemark" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('Whether to refund the handling fee')" prop="isRefundFee">
              <el-radio-group v-model="tradingRecord.isRefundFee" size="medium">
                <el-radio label="1" border>{{$t('Refund fee')}}</el-radio>
                <el-radio label="0" border>{{$t('Non-refundable service fee')}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button size="medium" type="primary" :loading="isloading" @click="onSubmit('tradingRecord')">{{$t('submitReversal')}}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
</template>

<script>

import transactionInfo from '@/api/transactionInfo'

export default {
  data () {
    return {
      isloading: false,
      queryForm: {},
      //交易记录
      tradingRecord: null,
    }
  },
  computed: {
    cardBody () { return this.$store.state.style.cardBody },
    mateParam () { return this.$route.meta.params },
    rules () {
      return {
        param: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
      }
    },
    rules2 () {
      return {
        isRefundFee: [{ required: true, message: this.$t('Please choose whether to refund the service fee'), trigger: 'blur' }],
      }
    }
  },
  methods: {
    onQuery (refName) {
      let _this = this;

      _this.$refs[refName].validate(valid => {
        if (!valid) return false;

        _this.isloading = true;
        transactionInfo.getTransactionInfoByCode({
          param: { transactionCode: _this[refName].param },
          success: res => {
            if (res.code == 200 && _this.mateParam.busCode.indexOf(Number(res.data.businessCode)) > -1) {
              res.data.businessName = _this.$t(res.data.businessName)
              // if (res.data.status == 2) {
              //   _this.$message.error(_this.$t(res.data.msg || '400001:The transaction has been completed!'));
              //   return;
              // } 
              if (res.data.status == 3) {
                _this.$message.warning(_this.$t(res.data.msg || 'The transaction is not successful,not Cancel transaction'));
                return;
              } else if (res.data.status == 4) {
                _this.$message.warning(_this.$t(res.data.msg || 'Transaction in cancellation,Do not repeat operations'));
                return;
              } else if (res.data.status == 5) {
                _this.$message.warning(_this.$t(res.data.msg || 'The transaction has been cancelled,Do not repeat operations'));
                return;
              }
              else if (res.msg) {
                _this.$message.warning(_this.$t(res.data.msg));
                return;
              }


              _this.tradingRecord = {
                businessName: _this.$t(res.data.businessName),
                amount: res.data.amount,
                createTime: _this.$manba(res.data.createTime).format('ymdhms'),
                id: res.data.id,
                transactionRemark: res.data.transactionRemark
              }
            }
            else _this.$message.warning(_this.$t(res.msg || 'The trading data does not match the positive type'))
            _this.isloading = false;
          }
        })
      })
    },
    onSubmit (refName) {
      let _this = this;

      this.$refs[refName].validate(valid => {
        if (!valid) return false;

        transactionInfo.transactionReversal({
          param: { type: _this.mateParam.type, transactionId: _this[refName].id, refundFee: _this[refName].isRefundFee },
          success: res => {
            if (res.code == 200)
              _this.$message.success(_this.$t('operateSuccessfully'));
            else
              _this.$message.warning(_this.$t(res.msg));
          }
        })
      })
    },
  },
  mounted () {
    console.log(this.$route.meta.params);
  }
}
</script>

<style lang="less" scoped>
.account-remittance-not-account.business-handling {
  height: 100%;
  width: 100%;
  .el-card__body {
    position: relative;
    .form-container {
      position: absolute;
      top: 60px;
      bottom: 80px;
      left: 20px;
      right: 0;
      padding-right: 20px;
      overflow: auto;
    }
  }
  .el-form {
    height: auto;
  }
  .update-el-divider {
    margin-top: 40px;
  }
}
</style>